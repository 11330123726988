import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import TemplateLandingPage from "./components/template-landing-page";

function App() {
    return (
        <div className="container-fluid">
            <TemplateLandingPage />
        </div>
    );
}

export default App;
