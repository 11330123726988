import React, {Component} from 'react';
import {Badge, Button, Card,} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripLines, faTrashAlt, faLongArrowAltLeft, faCheck } from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Droppable, Draggable as FreeDraggable } from "react-beautiful-dnd";
import Draggable from "react-draggable";
import axios from "axios";
import {apiKey, apiURL} from "../config";
import Select from "react-select";

const getItems = (count) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "#ffc107" : null,

    // styles we need to apply on draggables
    ...draggableStyle
});

class TemplateEdit extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            cursorX: 0,
            cursorY: 0,
            clickedX: 0,
            clickedY: 0,
            dragStartX: 0,
            dragStartY: 0,
            flowerList: [],
            draggingDiv: null,
            sizeList: {
                'm': 'Medium',
                'l': 'Large'
            },
            imageSource: '/assets/img/blank-grid.png',
            items: getItems(10),
            adjustXY: 0,
            newFlower: 0,
            flowerImagePX: 0,
            flowerSize: '',
            flowerSizeName: '',

            flowerMedium: {},
            flowerMediumSize: 0,
            flowerLarge: {},
            flowerLargeSize: 0,

            templateBackgrounds: [
                {
                    'label': 'Blank Grid',
                    'value': '/assets/img/blank-grid.png'
                },
                {
                    'label': 'Bouquet 1',
                    'value': '/assets/img/template-edit-bouquet-1.png'
                },
                {
                    'label': 'Bouquet 2',
                    'value': '/assets/img/template-edit-bouquet-2.png'
                },
                {
                    'label': 'Bouquet 3',
                    'value': '/assets/img/template-edit-bouquet-3.png'
                }
            ]
        };
        this.onDragEnd = this.onDragEnd.bind(this);
        this.flowerDragEnd = this.flowerDragEnd.bind(this);
    }
    componentDidMount() {
        this.loadTemplateData();
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const flowerList = reorder(
            this.state.flowerList,
            result.source.index,
            result.destination.index
        );
        this.setState({
            flowerList
        });
    }
    onDelete = (deleteIndex) => {
        let flowerList = [];
        this.state.flowerList.map((flower, index) => {
            if (index !== deleteIndex) {
                flowerList = [...flowerList, flower];
            }
            return null;
        });
        this.setState({flowerList});
    };
    selectNewFlower = () => {
        this.setState({ newFlower: 1});
    };
    selectNewFlowerSize = (e) => {
        if (e.target.value !== '') {
            this.setState({
                flowerSize: e.target.value,
                flowerSizeName: e.target[e.target.selectedIndex].text
            });
        }
    };
    cancelNewFlower = () => {
        this.setState({
            newFlower: 0,
            flowerSize: '',
            flowerSizeName: ''
        });
    };
    changeNewFlowerSize = () => {
      this.setState({
          flowerSize: '',
          flowerSizeName: ''
      });
    };
    updateCursorPosition = (e) => {
        let RootDiv = document.getElementById("root");
        let rootXY = RootDiv.getBoundingClientRect();
        //let rootX = parseInt(rootXY.x);
        let rootY = parseInt(rootXY.y);

        let ImageDiv = document.getElementById("imageContainer");
        let imageXY = ImageDiv.getBoundingClientRect();
        let imageX = parseInt(imageXY.x);
        let imageY = parseInt(imageXY.y);

        let cursorX = parseInt(e.pageX);
        let cursorY = parseInt(e.pageY);

        let clickedX = cursorX - imageX;
        let clickedY = cursorY - imageY + rootY;
        this.setState({
            cursorX: clickedX,
            cursorY: clickedY,
        });
    };
    selectFlowerPosition = () => {
        if (this.state.newFlower === 1 && this.state.flowerSize !== '') {
            this.setState({
                clickedX: this.state.cursorX,
                clickedY: this.state.cursorY,
                flowerList: [...this.state.flowerList, {
                    x: this.state.cursorX,
                    y: this.state.cursorY,
                    size: this.state.flowerSize,
                    sizeName: this.state.flowerSizeName
                }]
            });
        }
    };
    doneAdjustXY = () => {
        this.setState({
            newFlower: 0,
            adjustXY: 0,
            flowerSize: '',
            flowerSizeName: ''
        });
    };
    onChangeFlowerSize = (e, flowerSize) => {
        // AXIOS HERE
        axios.get(apiURL + '/api/getFlowerDetails', {params: {
                apiKey: apiKey,
                flowerId: e.value,
            }})
            .then((response) => {
                if (typeof(response.data.imageDetails) !== 'undefined')
                {

                    if (flowerSize === 'medium') {
                        this.setState({
                            flowerMedium: response.data.imageDetails,
                            flowerMediumSize: response.data.imageDetails.width
                        });
                    }

                    if (flowerSize === 'large') {
                        this.setState({
                            flowerLarge: response.data.imageDetails,
                            flowerLargeSize: response.data.imageDetails.width
                        });
                    }

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    manualChangeFlowerSize = (flowerId, flowerSize) => {
        // AXIOS HERE
        axios.get(apiURL + '/api/getFlowerDetails', {params: {
                apiKey: apiKey,
                flowerId: flowerId,
            }})
            .then((response) => {
                if (typeof(response.data.imageDetails) !== 'undefined')
                {

                    if (flowerSize === 'medium') {
                        this.setState({
                            flowerMedium: response.data.imageDetails,
                            flowerMediumSize: response.data.imageDetails.width
                        });
                    }

                    if (flowerSize === 'large') {
                        this.setState({
                            flowerLarge: response.data.imageDetails,
                            flowerLargeSize: response.data.imageDetails.width
                        });
                    }

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    onChangeTemplateBackground = (e) => {
        this.setState({imageSource: e.value});
    };
    flowerDragStart = (e, draggingDiv) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            dragStartX: this.state.cursorX,
            dragStartY: this.state.cursorY,
            draggingDiv: draggingDiv
        });
    };
    flowerDragging = (e) => {
        // console.log("flowerDragging", e.srcElement.id, e.toElement.id, e.target.id, e);

    };
    flowerDragEnd = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let newX = this.state.cursorX - this.state.dragStartX;
        let newY = this.state.cursorY - this.state.dragStartY;

        //console.log("flowerDragEnd", e.srcElement.id, e.toElement.id, e.target.id, e);
        const { draggingDiv } = this.state;
        let newFlowerInfo = [];
        this.state.flowerList.map((flower, index) => {
            if (index === draggingDiv) {
                newFlowerInfo = [...newFlowerInfo, {
                    "x" : flower.x + newX,
                    "y" : flower.y + newY,
                    "size" : flower.size,
                    "sizeName" : flower.sizeName
                }];
            } else {
                newFlowerInfo = [...newFlowerInfo, flower];
            }
            return null;
        });
        this.setState({flowerList: newFlowerInfo});
    };

    saveTemplateData = () => {
        // SAVE FUNCTION
        const { flowerList } = this.state;
        const { selectedTemplate } = this.props;
        // AXIOS HERE
        axios.post(apiURL + '/api/saveTemplate', {flowerList: JSON.stringify(flowerList), apiKey: apiKey, selectedTemplate: selectedTemplate})
            .then(function (response) {
                console.log(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    duplicateTemplateData = () => {
        // SAVE FUNCTION
        const { flowerList } = this.state;
        // AXIOS HERE
        axios.post(apiURL + '/api/saveTemplate', {flowerList: JSON.stringify(flowerList), apiKey: apiKey, selectedTemplate: 0})
            .then(function (response) {
                console.log(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    loadTemplateData = () => {
        // LOAD FUNCTION
        console.log("loadTemplateData");
        const { selectedTemplate } = this.props;
        // AXIOS HERE
        axios.get(apiURL + '/api/getTemplatePreview', {params: {
                selectedTemplate: selectedTemplate,
                apiKey: apiKey
            }})
            .then((response) => {
                if (typeof(response.data.template) !== 'undefined')
                {
                    if (typeof(response.data.template.data) !== 'undefined')
                    {
                        if (response.data.template.data !== '')
                        {
                            this.setState({
                                flowerList: JSON.parse(response.data.template.data),
                                flowerMediumSize: response.data.flowerMediumSize,
                                flowerLargeSize: response.data.flowerLargeSize
                            });
                            this.manualChangeFlowerSize(12, 'large');
                            this.manualChangeFlowerSize(1, 'medium');
                        }
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    cancelEdit = () => {
        this.props.editTemplate(null, 0);
    };

    render()
    {
        const { flowerMediumSize, flowerLargeSize, adjustXY, imageSource, newFlower, flowerSize, flowerSizeName, flowerList, flowerMedium, flowerLarge, templateBackgrounds } = this.state;
        const { flowersMedium, flowersLarge } = this.props;
        return (
            <div className="container-fluid">
                <div className="row my-2">
                    <div className="col-2">
                        <Button variant="secondary" className="mr-2" onClick={this.cancelEdit}><FontAwesomeIcon icon={faLongArrowAltLeft} /></Button>
                    </div>
                    <div className="col">
                        <table className="table table-borderless" style={{zIndex: 10000, position: "relative"}}>
                            <tbody>
                                <tr>
                                    <td className="text-right">M</td>
                                    <td><Select options={flowersMedium} style={{zIndex: 10000, position: "relative"}} name="sizeMedium" id="sizeMedium" onChange={(e) => this.onChangeFlowerSize(e,'medium')} /></td>
                                    <td className="text-right">L</td>
                                    <td><Select options={flowersLarge} style={{zIndex: 10000, position: "relative"}} name="sizeLarge" id="sizeLarge" onChange={(e) => this.onChangeFlowerSize(e,'large')} /></td>
                                    <td className="text-right">BG</td>
                                    <td><Select options={templateBackgrounds} style={{zIndex: 10000, position: "relative"}} name="templateBackground" id="templateBackground" onChange={(e) => this.onChangeTemplateBackground(e)} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-3 text-right">
                        <Button variant="success" onClick={this.saveTemplateData}>Save Template</Button>
                        <Button className="ml-2" variant="warning" onClick={this.duplicateTemplateData}>Save as New Template</Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col mr-0 px-1">
                        <Card>
                            <Card.Body className="p-0">
                                {newFlower === 1 && flowerList &&
                                    <div
                                        className="text-center"
                                        id="imageContainer"
                                        style={{position: 'relative', overflow: "hidden", zIndex: 1, width: "1000px", height: "1000px", backgroundSize: "100% 100%", backgroundImage: "url('" + imageSource + "')"}}
                                        onMouseMove={this.updateCursorPosition}
                                        onClick={this.selectFlowerPosition}
                                    >
                                        {flowerList.map((flower, index) => {
                                            flower.image = "/assets/img/roses-red-1.png";
                                            if (flower.size === 'm') {
                                                if (flowerMedium === '') {
                                                    flower.image = "/assets/img/roses-yellow-1.png";
                                                } else {
                                                    flower.image = "/assets/img/" + flowerMedium.imageUrl + "1.png";
                                                }
                                                flower.width = flowerMediumSize;
                                            }
                                            else if (flower.size === 'l') {
                                                if (flowerLarge === '') {
                                                    flower.image = "/assets/img/lily-orange-5.png";
                                                } else {
                                                    flower.image = "/assets/img/" + flowerLarge.imageUrl + "1.png";
                                                }
                                                flower.width = flowerLargeSize;
                                            }

                                            flower.left = -Math.abs(parseInt(flower.width) / 2);
                                            flower.top = -Math.abs(parseInt(flower.width) / 2);

                                            return (
                                                <div
                                                    key={index}
                                                    style={{
                                                        position: 'absolute',
                                                        zIndex: index+1,
                                                        left: parseInt(flower.x),
                                                        top: parseInt(flower.y)
                                                    }}
                                                >
                                                    <div style={{position: 'relative'}}>
                                                        <img
                                                            src={flower.image}
                                                            id={"img" + index}
                                                            width={flower.width}
                                                            height={flower.width}
                                                            alt={flower.sizeName}
                                                            style={{
                                                                position: 'absolute',
                                                                zIndex: index+1,
                                                                left: flower.left,
                                                                top: flower.top
                                                            }}
                                                        />
                                                        <Badge
                                                            id={"badge" + index}
                                                            variant="warning"
                                                            style={{
                                                                position: 'absolute',
                                                                zIndex: index+1,
                                                                left: -10,
                                                                top: -10
                                                            }}
                                                        >{index+1}</Badge>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                }
                                {newFlower === 0 && flowerList &&
                                    <div
                                        className="text-center"
                                        id="imageContainer"
                                        style={{position: 'relative', overflow: "hidden", zIndex: 1, width: "1000px", height: "1000px", backgroundSize: "100% 100%", backgroundImage: "url('" + imageSource + "')"}}
                                        onMouseMove={this.updateCursorPosition}
                                    >
                                        {flowerList.map((flower, index) => {
                                            flower.image = "/assets/img/roses-red-1.png";
                                            if (flower.size === 'm') {
                                                if (typeof(flowerMedium.imageUrl) === 'undefined') {
                                                    flower.image = "/assets/img/roses-yellow-1.png";
                                                } else {
                                                    flower.image = "/assets/img/" + flowerMedium.imageUrl + "1.png";
                                                }
                                                flower.width = flowerMediumSize;
                                            }
                                            else if (flower.size === 'l') {
                                                if (typeof(flowerLarge.imageUrl) === 'undefined') {
                                                    flower.image = "/assets/img/lily-orange-5.png";
                                                } else {
                                                    flower.image = "/assets/img/" + flowerLarge.imageUrl + "1.png";
                                                }
                                                flower.width = flowerLargeSize;
                                            }

                                            console.log('flower 1');
                                            let numLeft =  Math.abs(parseInt(flower.width) / 2);
                                            let numTop =  Math.abs(parseInt(flower.width) / 2);
                                            flower.left = numLeft - numLeft - numLeft;
                                            flower.top = numTop - numTop - numTop;

                                            return (
                                                <Draggable
                                                    key={index}
                                                    onStart={(e) => this.flowerDragStart(e, index)}
                                                    onDrag={this.flowerDragging}
                                                    onStop={this.flowerDragEnd}
                                                    position={{x: parseInt(flower.x), y: parseInt(flower.y)}}
                                                    name={"dragme" + index}
                                                >
                                                    <div style={{position: 'relative'}}>
                                                        <img
                                                            src={apiURL + flower.image}
                                                            id={"img" + index}
                                                            width={flower.width}
                                                            height={flower.width}
                                                            alt={flower.sizeName}
                                                            style={{
                                                                position: 'absolute',
                                                                zIndex: index + 1,
                                                                left: flower.left,
                                                                top: flower.top
                                                            }}
                                                        />
                                                        <Badge
                                                            id={"badge" + index}
                                                            variant="warning"
                                                            style={{
                                                                position: 'absolute',
                                                                zIndex: index + 1,
                                                                left: -5,
                                                                top: -5
                                                            }}
                                                        >{index + 1}</Badge>
                                                    </div>
                                                </Draggable>)
                                            }
                                        )}
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col px-1">
                        <Card className="mb-2">
                            <Card.Body>
                                {adjustXY === 0 && newFlower === 0 &&
                                    <div className="row">
                                        <div className="col-12">
                                            <Button variant="info" onClick={this.selectNewFlower}>New Flower</Button>
                                        </div>
                                    </div>
                                }
                                {adjustXY === 0 && newFlower === 1 && flowerSize === '' &&
                                    <div className="row">
                                        <div className="col-4 align-middle">
                                            Flower Size:
                                        </div>
                                        <div className="col-8">
                                            <select name="flowerSize" className="form-control" onClick={this.selectNewFlowerSize}>
                                                <option value="">-- Flower Size --</option>
                                                <option value="s">Small</option>
                                                <option value="m">Medium</option>
                                                <option value="l">Large</option>
                                                <option value="xl">Extra Large</option>
                                            </select>
                                        </div>
                                    </div>
                                }
                                {adjustXY === 0 && newFlower === 1 && flowerSize !== '' &&
                                    <div className="row">
                                        <div className="col-12">
                                            <Button variant="secondary" className="mr-2" onClick={this.cancelNewFlower}>Cancel</Button>
                                            <Button variant="warning ml-1" onClick={this.changeNewFlowerSize}>Change Size</Button>
                                            <h3>Selected Size: {flowerSizeName}</h3>
                                        </div>
                                    </div>
                                }
                                {adjustXY === 1 &&
                                    <div className="row">
                                        <div className="col-12"><Button onClick={this.doneAdjustXY}><FontAwesomeIcon icon={faCheck} /> {' '} Done</Button></div>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                        {flowerList.length >0 &&
                            <Card>
                                <Card.Body style={{position: "relative", overflowY: "scroll", height: "662px"}} className="p-0">
                                    {flowerList && <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Droppable droppableId="flowerDrop">
                                            {(provided2) => (
                                                <div {...provided2.droppableProps} ref={provided2.innerRef}>
                                                {flowerList.map((flower, index) => {
                                                    return (
                                                        <FreeDraggable key={index} draggableId={index+1} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                >
                                                                    <div className="row box py-2 px-0 m-0 my-1 border-bottom no-cursor">
                                                                        <div className="col-1 cursor"><FontAwesomeIcon icon={faGripLines} /></div>
                                                                        <div className="col-1"><Badge variant="danger">{index+1}</Badge></div>
                                                                        <div className="col-2"><img src={apiURL + "/assets/img/" + flower.size + ".png"} alt={flower.sizeName} width="25" /></div>
                                                                        <div className="col-5">{flower.x} / {flower.y}</div>
                                                                        <div className="col-2 text-right"><Button onClick={() => this.onDelete(index)}><FontAwesomeIcon icon={faTrashAlt} /></Button></div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </FreeDraggable>
                                                    );
                                                })}
                                            </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>}
                                </Card.Body>
                            </Card>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default TemplateEdit;
