import React, {Component, Fragment} from 'react';
import Select from 'react-select';
import {Button} from "react-bootstrap";
import axios  from 'axios'
import { apiKey, apiURL } from '../config'
import { faSync } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class TemplateList extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            selectedTemplate: null,
            templatePreview: '',
            templates: [],
            flowers: [],
            flowerSmall: '',
            flowerMedium: '',
            flowerLarge: '',
            flowerExtraLarge: '',
            previewImage: null
        };
    }
    componentDidMount() {
        this.getTemplateList();
    }
    onChange = (e) => {
          this.setState({"selectedTemplate": e.value, "templatePreview": 0, "previewImage": null});
          this.getTemplatePreview(e.value, this.state.flowerMedium, this.state.flowerLarge);
    };
    refreshTemplate = () => {
          this.getTemplatePreview(this.state.selectedTemplate, this.state.flowerMedium, this.state.flowerLarge);
    };

    onChangeFlowerSize = (e, flowerSize) => {
        console.log("State", this.state);
        let flowerSmall = '';
        if (flowerSize === 'small') {
            flowerSmall = e.value;
        } else {
            flowerSmall = this.state.flowerSmall;
        }

        let flowerMedium = '';
        if (flowerSize === 'medium') {
            flowerMedium = e.value;
        } else {
            flowerMedium = this.state.flowerMedium;
        }

        let flowerLarge = '';
        if (flowerSize === 'large') {
            flowerLarge = e.value;
        } else {
            flowerLarge = this.state.flowerLarge;
        }

        let flowerExtraLarge = '';
        if (flowerSize === 'extraLarge') {
            flowerExtraLarge = e.value;
        } else {
            flowerExtraLarge = this.state.flowerExtraLarge;
        }

        // AXIOS HERE
        axios.get(apiURL + '/api/getTemplatePreview', {params: {
                selectedTemplate: this.state.selectedTemplate,
                apiKey: apiKey,
                flowerSmall: flowerSmall,
                flowerMedium: flowerMedium,
                flowerLarge: flowerLarge,
                flowerExtraLarge: flowerExtraLarge
        }})
            .then((response) => {
                if (typeof(response.data.previewImage) !== 'undefined') {
                    this.setState({
                        "previewImage": response.data.previewImage,
                        flowerSmall: flowerSmall,
                        flowerMedium: flowerMedium,
                        flowerLarge: flowerLarge,
                        flowerExtraLarge: flowerExtraLarge
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });


    };

    editTemplate = () => {
        const { selectedTemplate, flowersMedium, flowersLarge } = this.state;
        if (selectedTemplate !== '' && selectedTemplate !== null)
        {
            this.props.editTemplate(selectedTemplate, 1, flowersMedium, flowersLarge);
        }
    };
    getTemplateList = () => {
        // AXIOS HERE
        axios.get(apiURL + '/api/getTemplateList', {params: {apiKey: apiKey}})
            .then((response) => {
                if (typeof(response.data.templates) !== 'undefined') {
                    this.setState({ "templates": response.data.templates });
                }
                if (typeof(response.data.flowersMedium) !== 'undefined') {
                    this.setState({
                        flowers: response.data.flowers,
                        flowersMedium: response.data.flowersMedium,
                        flowersLarge: response.data.flowersLarge
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    getTemplatePreview = (selectedTemplate, flowerMedium, flowerLarge) => {

        // AXIOS HERE
        axios.get(apiURL + '/api/getTemplatePreview', {params: {
                selectedTemplate: selectedTemplate,
                apiKey: apiKey,
                flowerMedium: flowerMedium,
                flowerLarge: flowerLarge
            }})
            .then( (response) => {
                if (typeof(response.data.previewImage) !== 'undefined') {
                    this.setState({ "previewImage": response.data.previewImage, templatePreview: 1 });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    render()
    {
        const { templates, templatePreview, previewImage, flowersMedium, flowersLarge } = this.state;
        console.log("Flowers", flowersMedium, flowersLarge);
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1>Bouquet Builder Template Tool</h1>
                        <h4>Select a template to edit</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Select options={templates}  name="selectedTemplate" id="selectedTemplate" onChange={this.onChange} />
                    </div>
                    <div className="col">
                        <Button variant="success" onClick={this.editTemplate}>Edit</Button>
                        <Button className="ml-1" variant="warning" onClick={this.refreshTemplate}><FontAwesomeIcon icon={faSync} /></Button>
                    </div>
                </div>
                <hr noshade="true" />
                <div className="row mt-3">
                    <div className="col">
                        {templatePreview === 1 &&
                            <Fragment>
                                <h2>Template Preview</h2>
                                <img src={"data:image/png;base64,"+previewImage} alt="template preview" width="750" />
                            </Fragment>
                        }
                    </div>
                    <div className="col">
                        <h4>Medium</h4>
                        <Select options={flowersMedium}  name="sizeMedium" id="sizeMedium" onChange={(e) => this.onChangeFlowerSize(e,'medium')} />
                        <br />

                        <h4>Large</h4>
                        <Select options={flowersLarge}  name="sizeLarge" id="sizeLarge" onChange={(e) => this.onChangeFlowerSize(e,'large')} />
                        <br />
                    </div>
                </div>

            </div>
        );
    }
}

export default TemplateList;
