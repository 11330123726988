import React, {Component} from 'react';
import TemplateList from "./template-list";
import TemplateEdit from "./template-edit";

class Template extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            selectedTemplate: null,
            editTemplate: 0,
            flowers: [],
            flowersMedium: [],
            flowersLarge: []
        };
    }

    editTemplate = (selectedTemplate, editTemplate, flowersMedium, flowersLarge) => {
        this.setState({selectedTemplate: selectedTemplate, editTemplate: editTemplate, flowersMedium:flowersMedium, flowersLarge:flowersLarge });
    };

    render()
    {
        const { editTemplate, selectedTemplate, flowersMedium, flowersLarge, flowers } = this.state;
        console.log("flowers", flowers);
        if (editTemplate !== 1) {
            return (
                <TemplateList
                    editTemplate={this.editTemplate}
                />
            );
        } else {
            return (
                <TemplateEdit
                    editTemplate={this.editTemplate}
                    selectedTemplate={selectedTemplate}
                    flowersMedium={flowersMedium}
                    flowersLarge={flowersLarge}
                />
            );
        }

    }
}

export default Template;
